import { call, put, takeEvery, take } from "redux-saga/effects"
import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import i18n from "../../utilities/i18n"
const VEHICLE_MAINTENANCE_SCHEDULES = "/services/recommended"

export function* createOrUpdateVehicle(action) {
  try {
    yield put({ type: "VEHICLE_CREATE_OR_UPDATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: "/vehicles/" + (action.payload.id ? action.payload.id : ""),
      body: { vehicle: action.payload }
    }

    const response = yield call(
      action.payload.id ? api.utility.put : api.utility.post,
      api.path(request.url),
      { body: request.body }
    )

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: action.payload.id ? "VEHICLE_LOAD_SUCCEEDED" : "VEHICLE_CREATE_SUCCEEDED",
      payload: {
        vehicle: response.body.vehicle
      }
    })

    const afterRequestData = {
      vehicle: response.body.vehicle
    }

    if (action.callback) yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_CREATE_OR_UPDATE_FAILED" })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors)
    }

    if (action.callback) yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createVehicle

export function* bulkUpsertVehicles(action) {
  try {
    yield put({ type: "BULK_UPSERT_VEHICLES_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: "/vehicles/bulk_upsertions",
      body: { fileData: action.payload.file }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "BULK_UPSERT_VEHICLES_SUCCEEDED",
      payload: {
        success: response.success
      }
    })

    const afterRequestData = {
      alertMessage: action.payload.t("bulkUploadSuccessLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "BULK_UPSERT_VEHICLES_FAILED" })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors),
      alertMessage: action.payload.t("bulkUploadFailureLabel"),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* bulkUpsertVehicles

export function* deleteVehicle(action) {
  try {
    yield put({ type: "VEHICLE_DELETE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const vehicleId = action.payload.vehicleId

    const request = {
      url: `/vehicles/${vehicleId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_DELETE_SUCCEEDED",
      payload: {
        vehicle: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "Vehicle deleted",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_DELETE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteVehicle

export function* deactivateVehicle(action) {
  try {
    yield put({ type: "VEHICLE_DEACTIVATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const vehicleId = action.payload.vehicleId

    const request = {
      url: `/vehicles/${vehicleId}/deactivate`,
      body: {}
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_DEACTIVATE_SUCCEEDED",
      payload: {
        vehicle: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "Vehicle deactivate",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_DEACTIVATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteVehicle

export function* loadVehicle(action) {
  try {
    const defaultOptions = {
      dataType: "standard"
    }

    const payloadOptions = action.payload.options || {}

    const options = {
      ...defaultOptions,
      ...payloadOptions
    }

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //   LOAD VEHICLE DATA    //
    ////////////////////////////
    yield put({ type: "VEHICLE_LOAD_REQUESTED" })

    const vehicleId = action.payload.vehicleId
    const search = action.payload.options.search
    let body = {}
    if (search) {
      body.search = true
    }

    let url = `/vehicles/${vehicleId}`
    const vehicleRequest = {
      url: url,
      body: body
    }

    if (options["dataType"] === "extended") {
      yield put({ type: "MAINTENANCE_INTERVAL_SAGA", payload: { vehicleIds: [vehicleId] } })
      vehicleRequest.body.include = "all"
    }

    const vehicleResponse = yield call(api.utility.get, api.path(vehicleRequest.url), {
      body: vehicleRequest.body
    })

    apiErrorHelpers.catchErrors(vehicleResponse.body)

    yield put({
      type: "VEHICLE_LOAD_SUCCEEDED",
      payload: {
        vehicle: vehicleResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLE_DATA_TYPE",
      payload: {
        dataType: options["dataType"]
      }
    })

    if (search && action.callback) {
      yield call(action.callback, vehicleResponse.body)
    }

    if (!search) {
      ////////////////////////////

      ////////////////////////////
      //   LOAD VEHICLES DATA   //
      ////////////////////////////

      yield put({ type: "VEHICLES_LOAD_REQUESTED" })

      const vehiclesRequest = {
        url: "/vehicles",
        body: {}
      }

      const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
        body: vehiclesRequest.body
      })

      apiErrorHelpers.catchErrors(vehiclesResponse.body)

      yield put({
        type: "VEHICLES_LOAD_SUCCEEDED",
        payload: {
          vehicles: vehiclesResponse.body
        }
      })

      yield put({
        type: "SET_VEHICLES_DATA_TYPE",
        payload: { dataType: "standard" }
      })
      ////////////////////////////

      ////////////////////////////
      //    LOAD USERS DATA     //
      ////////////////////////////
      // yield put({ type: "USERS_LOAD_REQUESTED" })
      //
      // const usersRequest = {
      //   url: "/fleet_users",
      //   body: {}
      // }
      //
      // const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
      //   body: usersRequest.body
      // })
      //
      // apiErrorHelpers.catchErrors(usersResponse.body)
      //
      // yield put({
      //   type: "USERS_LOAD_SUCCEEDED",
      //   payload: {
      //     users: usersResponse.body
      //   }
      // })
      ////////////////////////////

      ////////////////////////////
      //   LOAD SERVICES DATA   //
      ////////////////////////////
      yield put({ type: "SERVICES_LOAD_REQUESTED" })

      const servicesRequest = {
        url: "/services",
        body: { basic: true }
      }

      const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
        body: servicesRequest.body
      })

      apiErrorHelpers.catchErrors(servicesResponse.body)

      yield put({
        type: "SERVICES_LOAD_SUCCEEDED",
        payload: {
          services: servicesResponse.body
        }
      })
      ////////////////////////////
    }
    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_LOAD_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadVehicle

export function* loadDropdown(type, getUrl, action) {
  yield put({ type: `${type.toUpperCase()}_LOAD_REQUESTED` })

  api.setAuthApi({
    Authorization: sessionHelpers.getApplicationCookie("authentication_token")
  })

  try {
    const vehiclesResponse = yield call(api.utility.get, api.path(getUrl(action)), {})

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: `${type.toUpperCase()}_LOAD_SUCCEEDED`,
      payload: {
        [type]: vehiclesResponse.body // Got lucky, no fancy plurals used here.
      }
    })
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({
      type: `${type.toUpperCase()}_LOAD_SUCCEEDED`,
      payload: {
        [type]: []
      }
    })
    apiErrorHelpers.consoleLog(errors)
  }
}

function getMakesUrl(action) {
  return "/vehicles/years/" + action.payload.yearId + "/makes"
}

function getModelsUrl(action) {
  const { yearId, makeId } = action.payload
  return "/vehicles/years/" + yearId + "/makes/" + makeId + "/models"
}

function getEnginesUrl(action) {
  const { yearId, makeId, modelId, subModelId } = action.payload
  return (
    "/vehicles/years/" +
    yearId +
    "/makes/" +
    makeId +
    "/models/" +
    modelId +
    "/submodels/" +
    subModelId
  )
}

function getYearsUrl(action) {
  return "/vehicles/years"
}

export function* loadVehicles(action) {
  try {
    const authToken = sessionHelpers.getApplicationCookie("authentication_token")
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}
    api.setAuthApi({
      Authorization: authToken
    })

    ////////////////////////
    // LOAD VEHICLES DATA //
    ////////////////////////
    let body = {}
    if (payloadOptions.fleet_id) {
      body.fleet_id = payloadOptions.fleet_id
      body.from = "fleet_web"
    }
    const vehiclesRequest = {
      url: "/vehicles",
      body: body
    }

    if (action.payload && action.payload.download && action.payload.language) {
      const url = `${vehiclesRequest.url}.csv?auth_token=${authToken}&lancode=${
        action.payload.language && action.payload.language.toUpperCase()
      }&isDomainShellCanada=${action.payload.isDomainShellCanada}`
      window.open(api.path(url))
      return
    }

    yield put({ type: "SET_VEHICLE_LOADING" })
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })
    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    if (action.payload && action.payload.addMaintenance) {
      vehiclesRequest.body.request = "active_order"
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body,
        section: payloadOptions.section
      }
    })
    if (action.callback) {
      yield call(action.callback, vehiclesResponse.body)
    }

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })
    ////////////////////////

    ////////////////////////
    //  LOAD USERS DATA   //
    ////////////////////////
    // yield put({ type: "USERS_LOAD_REQUESTED" })
    //
    // const usersRequest = {
    //   url: "/fleet_users",
    //   body: {}
    // }
    //
    // const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
    //   body: usersRequest.body
    // })
    //
    // apiErrorHelpers.catchErrors(usersResponse.body)
    //
    // yield put({
    //   type: "USERS_LOAD_SUCCEEDED",
    //   payload: {
    //     users: usersResponse.body
    //   }
    // })
    ////////////////////////

    yield put({ type: "UNSET_VEHICLE_LOADING" })
    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadVehicles

export function* loadBasicVehicles(action) {
  try {
    const authToken = sessionHelpers.getApplicationCookie("authentication_token")
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}
    api.setAuthApi({
      Authorization: authToken
    })

    ////////////////////////
    // LOAD VEHICLES DATA //
    ////////////////////////

    const vehiclesRequest = {
      url: "/vehicles/all_vehicles",
      body: {}
    }

    yield put({ type: "SET_VEHICLE_BASIC_LOADING" })
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })
    yield put({ type: "VEHICLES_BASIC_LOAD_REQUESTED" })

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_BASIC_LOAD_SUCCEEDED",
      payload: {
        basicVehicles: vehiclesResponse.body
      }
    })
    if (action.callback) {
      yield call(action.callback, vehiclesResponse.body)
    }
    ////////////////////////

    ////////////////////////
    //  LOAD USERS DATA   //
    ////////////////////////
    ////////////////////////

    yield put({ type: "UNSET_VEHICLE_BASIC_LOADING" })
    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadBasicVehicles

export function* updateVehicle(action) {
  try {
    yield put({ type: "VEHICLE_UPDATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const vehicleId = action.payload.formData.id

    const request = {
      url: `/vehicles/${vehicleId}`,
      body: {
        vehicle: {
          ...action.payload.formData
        }
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_UPDATE_SUCCEEDED",
      payload: {
        vehicle: response.body.vehicle
      }
    })

    const afterRequestData = {
      alertMessage: "Vehicle updated",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_UPDATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "failure"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateVehicle

export function* fetchVehicleServices(action) {
  try {
    yield put({ type: "VEHICLE_SERVICE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { vehicleId, page } = action.payload

    const request = {
      url: `/vehicles/${vehicleId}/show_services?page=${page}`
    }

    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_SERVICES_SUCCEEDED",
      payload: {
        vehicleServices: response.body
      }
    })

    if (action.callback) {
      yield call(action.callback, "success")
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_SERVICES_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "failure"
    }

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* transferVehicles(action) {
  try {
    yield put({ type: "VEHICLE_TRANSFER_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    const vehicle_ids = action.payload.options.vehicle_ids
    const old_fleet_ids = action.payload.options.old_fleet_ids
    const new_fleet_id = action.payload.options.new_fleet_id

    const request = {
      url: `/vehicles/transfer_vehicles`,
      body: {
        vehicle_ids: vehicle_ids,
        old_fleet_ids: old_fleet_ids,
        new_fleet_id: new_fleet_id
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    const afterRequestData = {
      alertMessage: "Vehicles are transfered successfully",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_TRANSFER_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "failure"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* transferVehicles

export function* loadVehicleGloveBox(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "VEHICLE_GLOVEBOX_UPDATE_REQUESTED" })
    const { vehicleId } = action.payload

    const request = {
      url: `/glovebox_items/?vehicle_id=${vehicleId}`
    }
    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_GLOVEBOX_UPDATE_SUCCEEDED",
      payload: {
        glovebox: response.body
      }
    })
    yield put({ type: "UNSET_LOADING" })
    if (action.callback) {
      yield call(action.callback, "success")
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_GLOVEBOX_UPDATE_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* saveDocument(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SAVE_OR_UPDATE_UPLOADED_DOCUMENT_REQUESTED" })

    const { formData, documentItemId } = action.payload

    const request = {
      url: `/glovebox_items`
    }
    if (documentItemId) {
      request.url = `${request.url}/${documentItemId}`
    }

    let baseUri = ""
    if (process.env.NODE_ENV === "production") {
      baseUri = window.location.origin
    } else {
      baseUri = api.apiUrl
    }
    yield call(fetch, `${baseUri}${api.basePath}${request.url}`, {
      body: formData,
      method: documentItemId ? "put" : "post",
      headers: {
        Authorization: sessionHelpers.getApplicationCookie("authentication_token")
      }
    })
    const afterRequestData = {
      alertMessage: documentItemId
        ? i18n.t("vehicle:updateDocumentSuccessLabel")
        : i18n.t("vehicle:uploadDocumentSuccessLabel"),
      alertType: "success"
    }
    yield put({
      type: "SAVE_OR_UPDATE_UPLOADED_DOCUMENT_SUCCEEDED",
      payload: afterRequestData
    })

    yield put({ type: "UNSET_LOADING" })
    yield call(action.callback, "success")
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    const afterRequestData = {
      alertMessage: i18n.t("vehicle:uploadDocumentFailedLabel"),
      alertType: "failure"
    }
    yield call(action.callback, "failure")
    yield put({
      type: "SAVE_OR_UPDATE_UPLOADED_DOCUMENT_FAILED",
      payload: afterRequestData
    })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* deleteDocument(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "DELETE_UPLOADED_DOCUMENT_REQUESTED" })
    const { id } = action.payload

    const request = {
      url: `/glovebox_items/${id}`
    }

    const response = yield call(api.utility.del, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)
    const afterRequestData = {
      alertMessage: i18n.t("vehicle:uploadDocumentRemoveSuccessLabel"),
      alertType: "success"
    }
    yield put({
      type: "DELETE_UPLOADED_DOCUMENT_SUCCEEDED",
      payload: afterRequestData
    })
    yield put({ type: "UNSET_LOADING" })
    yield call(action.callback, "success")
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    const afterRequestData = {
      alertMessage: i18n.t("vehicle:uploadDocumentRemoveFailedLabel"),
      alertType: "failure"
    }
    yield call(action.callback, "failure")
    yield put({
      type: "VDELETE_UPLOADED_DOCUMENT_FAILED",
      payload: afterRequestData
    })
    apiErrorHelpers.consoleLog(errors)
  }
}

export function* loadVehicleGeotabBatteryLifeInfo(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_BATTERY_LIFE_INFO_REQUESTED" })
    const { vehicleId } = action.payload

    const request = {
      url: `/geotab_vehicles/${vehicleId}/battery_life_info`
    }
    const response = yield call(api.utility.get, api.path(request.url))

    yield put({
      type: "VEHICLE_GEOTAB_BATTERY_LIFE_INFO_REQUEST_SUCCEEDED",
      payload: {
        batteryLifeInfo: response.body
      }
    })

    apiErrorHelpers.catchErrors(response.body)

    yield put({ type: "UNSET_LOADING" })
    if (action.callback) {
      yield call(action.callback, "success")
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_BATTERY_LIFE_INFO_REQUEST_FAILED" })
    apiErrorHelpers.consoleLog(errors)
  }
}

export function* loadVehicleGeotabEngineOilInfo(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_ENGINE_OIL_INFO_REQUESTED" })
    const { vehicleId } = action.payload

    const request = {
      url: `/geotab_vehicles/${vehicleId}/engine_oil_info`
    }
    const response = yield call(api.utility.get, api.path(request.url))

    yield put({
      type: "VEHICLE_GEOTAB_ENGINE_OIL_INFO_REQUEST_SUCCEEDED",
      payload: {
        engineOilLifeInfo: response.body
      }
    })

    apiErrorHelpers.catchErrors(response.body)

    yield put({ type: "UNSET_LOADING" })
    if (action.callback) {
      yield call(action.callback, "success")
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_ENGINE_OIL_INFO_REQUEST_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* loadVehicleGeotabTirePressureInfo(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_TIRE_PRESSURE_INFO_REQUESTED" })
    const { vehicleId } = action.payload

    yield put({
      type: "VEHICLE_GEOTAB_TIRE_PRESSURE_INFO_REQUEST_SUCCEEDED",
      payload: {
        tirePressureInfo: {}
      }
    })

    const request = {
      url: `/geotab_vehicles/${vehicleId}/tire_pressure_info`
    }
    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_GEOTAB_TIRE_PRESSURE_INFO_REQUEST_SUCCEEDED",
      payload: {
        tirePressureInfo: response.body
      }
    })
    yield put({ type: "UNSET_LOADING" })
    if (action.callback) {
      yield call(action.callback, "success")
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_TIRE_PRESSURE_INFO_REQUEST_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* loadVehicleGeotabFaultDataInfo(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_FAULT_CODES_INFO_REQUESTED" })
    const { vehicleId } = action.payload

    yield put({
      type: "VEHICLE_GEOTAB_FAULT_CODES_INFO_REQUEST_SUCCEEDED",
      payload: {
        faultCodesInfo: []
      }
    })

    const request = {
      url: `/geotab_vehicles/${vehicleId}/fault_data`
    }
    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_GEOTAB_FAULT_CODES_INFO_REQUEST_SUCCEEDED",
      payload: {
        faultCodesInfo: response.body
      }
    })
    yield put({ type: "UNSET_LOADING" })
    if (action.callback) {
      yield call(action.callback, "success")
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "VEHICLE_GEOTAB_FAULT_CODES_INFO_REQUEST_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* pullOemMaintenanceSchedules(action) {
  try {
    yield put({ type: "PULL_OEM_MAINTENANCE_SCHEDULES_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { vehicle } = action.payload

    const request = {
      url: `${VEHICLE_MAINTENANCE_SCHEDULES}/oem`,
      body: {
        vehicleId: vehicle.id,
        interval: vehicle.miles
      }
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    if (response.body) {
      const services = response.body.intervals || []
      yield put({
        type: "PULL_OEM_MAINTENANCE_SCHEDULES_SUCCEEDED",
        payload: {
          id: vehicle.id,
          oemServices: services.filter((service) => service)
        }
      })
    }

    if (action.callback) {
      yield call(action.callback, "success")
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "PULL_OEM_MAINTENANCE_SCHEDULES_FAILED" })

    yield call(action.callback, "failure")

    apiErrorHelpers.consoleLog(errors)
  }
} // function* pullOemMaintenanceSchedules

export default function* vehicleSaga() {
  yield takeEvery("VEHICLE_CREATE_OR_UPDATE_SAGA", createOrUpdateVehicle)
  yield takeEvery("VEHICLE_DELETE_SAGA", deleteVehicle)
  yield takeEvery("VEHICLE_LOAD_SAGA", loadVehicle)
  yield takeEvery("VEHICLE_UPDATE_SAGA", updateVehicle)
  yield takeEvery("VEHICLE_BULK_UPSERT_SAGA", bulkUpsertVehicles)
  yield takeEvery("VEHICLES_LOAD_SAGA", loadVehicles)
  yield takeEvery("VEHICLES_BASIC_LOAD_SAGA", loadBasicVehicles)
  yield takeEvery("YEARS_LOAD_SAGA", loadDropdown.bind(this, "years", getYearsUrl))
  yield takeEvery("MAKES_LOAD_SAGA", loadDropdown.bind(this, "makes", getMakesUrl))
  yield takeEvery("MODELS_LOAD_SAGA", loadDropdown.bind(this, "models", getModelsUrl))
  yield takeEvery("ENGINES_LOAD_SAGA", loadDropdown.bind(this, "engines", getEnginesUrl))
  yield takeEvery("VEHICLE_DEACTIVATE_SAGA", deactivateVehicle)
  yield takeEvery("VEHICLE_TRANSFER_SAGA", transferVehicles)
  yield takeEvery("VEHICLE_LOAD_SERVICES_SAGA", fetchVehicleServices)
  yield takeEvery("VEHICLE_GLOVEBOX_SAGA", loadVehicleGloveBox)
  yield takeEvery("SAVE_OR_UPDATE_UPLOADED_DOCUMENT", saveDocument)
  yield takeEvery("DELETE_UPLOADED_DOCUMENT", deleteDocument)
  yield takeEvery("VEHICLE_GEOTAB_ENGINE_OIL_INFO_LOAD_SAGA", loadVehicleGeotabEngineOilInfo)
  yield takeEvery("VEHICLE_GEOTAB_BATTERY_LIFE_INFO_LOAD_SAGA", loadVehicleGeotabBatteryLifeInfo)
  yield takeEvery("VEHICLE_GEOTAB_TIRE_PRESSURE_INFO_LOAD_SAGA", loadVehicleGeotabTirePressureInfo)
  yield takeEvery("VEHICLE_GEOTAB_FAULT_CODES__INFO_LOAD_SAGA", loadVehicleGeotabFaultDataInfo)
  yield takeEvery("PULL_OEM_MAINTENANCE_SCHEDULES", pullOemMaintenanceSchedules)
}
