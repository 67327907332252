import { select, call, put, takeEvery } from "redux-saga/effects"
import {
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada,
  isShell
} from "../../helpers/affiliationHelpers"
import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { addOrUpdateSentryContext } from "../../utilities/sentry"
import { loadAffiliation } from "./affiliationSaga"
import { setLanguage } from "../../helpers/translationHelpers"
import { CANDA_COUNTRY_CODE, US_COUNTRY_CODE, EN_LOCALE, FR_LOCALE } from "../../constants/users"
import { FMC_FLEET_ADMIN, FMC_FLEET_MANAGER } from "../../constants/roles"

export function* changePassword(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({
      type: "CHANGE_PASSWORD_REQUESTED"
    })

    const request = {
      url: "/customers/update_password",
      body: {
        current_password: action.payload.formData.currentPassword,
        email: action.payload.formData.email,
        new_password: action.payload.formData.newPassword,
        new_password_confirmation: action.payload.formData.newPasswordConfirmation
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "CHANGE_PASSWORD_SUCCEEDED"
    })

    const afterRequestData = {
      alertMessage: "Password changed",
      alertType: "success",
      status: "success"
    }

    yield call(action.callback, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "CHANGE_PASSWORD_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error",
      status: "failure"
    }

    yield call(action.callback, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // changePassword()

export function* forgotPassword(action) {
  try {
    yield put({
      type: "FORGOT_PASSWORD_REQUESTED"
    })

    const request = {
      url: "/customers/reset_password",
      body: {
        email: action.payload.formData.email
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FORGOT_PASSWORD_SUCCEEDED"
    })

    const afterRequestData = {
      alertMessage: action.payload.t("common:receiveEmailAlert"),
      alertType: "notice",
      status: "success"
    }

    yield call(action.callback, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FORGOT_PASSWORD_FAILED" })

    const alertMessage = errors.includes("There is no account associated with that e-mail address.")
      ? action.payload.t("common:noAccountFoundAlert")
      : apiErrorHelpers.toString(errors)

    const afterRequestData = {
      alertMessage: alertMessage,
      alertType: "error",
      status: "failure"
    }

    yield call(action.callback, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // forgotPassword()

export function* resetPassword(action) {
  try {
    yield put({
      type: "RESET_PASSWORD_REQUESTED"
    })

    const formData = action.payload.formData

    const request = {
      url: "/customers/reset_password",
      body: {
        new_password: formData.newPassword,
        new_password_confirmation: formData.newPasswordConfirmation,
        reset_token: formData.resetToken
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "RESET_PASSWORD_SUCCEEDED"
    })

    const afterRequestData = {
      alertMessage: "Your password has been reset",
      alertType: "success",
      status: "success"
    }

    yield call(action.callback, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "RESET_PASSWORD_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error",
      status: "failure"
    }

    yield call(action.callback, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // resetPassword()

export function* signUp(action) {
  try {
    //api called----> /api/v4/customers
    const request = {
      url: "/customers/fleet_signup",
      body: {
        fleet: {
          name: action.payload.formData.companyName,
          fleet_payment_type: "credit_card",
          vehicles_total: action.payload.formData.carCount,
          subdomain: action.payload.formData.subdomain
        },
        customer: {
          email: action.payload.formData.email,
          password: action.payload.formData.password,
          firstName: action.payload.formData.firstName,
          lastName: action.payload.formData.lastName,
          password_confirmation: action.payload.formData.confirmPassword,
          cellPhone: action.payload.formData.phoneNumber,
          zip: action.payload.formData.zipCode,
          country: isFleetMaintenanceHubCanada() ? CANDA_COUNTRY_CODE : US_COUNTRY_CODE,
          language: action.payload.toggle ? FR_LOCALE : EN_LOCALE,
          accepted_terms_and_conditions: action.payload.formData.acceptedTermsAndConditions,
          payment_method_nonce: action.payload.formData.payment_method_nonce,
          is_split_flag: action.payload.is_split_flag
        },
        affiliation: {
          communication_preferences_in_french:
            action.payload.formData.communicationPreferencesInFrench
        }
      }
    }
    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })
    apiErrorHelpers.catchErrors(response.body)

    const currentUser = response.body

    yield put({
      type: "CURRENT_USER_LOAD_SUCCEEDED",
      payload: {
        currentUser: currentUser
      }
    })

    //addOrUpdateSentryContext(currentUser)

    yield call(
      sessionHelpers.setApplicationCookie,
      "authentication_token",
      currentUser.authentication_token
    )

    yield call(sessionHelpers.setApplicationCookie, "current_user_id", currentUser.id)

    yield call(
      sessionHelpers.setApplicationCookie,
      "current_user_name",
      `${currentUser.firstName} ${currentUser.lastName}`
    )

    yield call(sessionHelpers.setApplicationCookie, "current_user_email", currentUser.email)
    yield call(sessionHelpers.setApplicationCookie, "current_user", currentUser)

    const afterRequestData = currentUser

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    const userExist = "please use another email address"

    const alertMessage = errors.some((error) => error.includes(userExist))
      ? { alertLabel: "userExistAlert", data: { email: action.payload.formData.email } }
      : apiErrorHelpers.toString(errors)

    const afterRequestData = {
      alertMessage: alertMessage,
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} //signUp

export function* signIn(action) {
  try {
    yield put({ type: "SIGN_IN_REQUESTED" })

    const request = {
      url: "/sessions",
      body: {
        email: action.payload.formData.email,
        password: action.payload.formData.password,
        key: action.payload.formData.key,
        query: "fleet_default",
        otp: action.payload.otp,
        subdomain: action.payload.subdomain,
        is_split_flag: action.payload.is_split_flag
      }
    }

    const response = yield call(api.utility.post, api.pathV6(request.url), { body: request.body })
    apiErrorHelpers.catchErrors(response.body)
    //TODO need to change this to check for fleet_id or FMC ROLE, trying not to force fmc to change fleet_id record
    // eslint-disable-next-line
    if (response.originalResponse.status === 200)
      if (!response.body.fleet_id) throw action.payload.t("common:notFleetCustomerAlert")

    yield put({
      type: "SIGN_IN_SUCCEEDED"
    })

    const currentUser = response.body || {}

    addOrUpdateSentryContext(currentUser)

    //TODO here we want to change from the auth user to the response header 'x-authentcation-jwt'
    const jwtToken = response.headers.get("x-authentication-jwt")
    if (!jwtToken) {
      // fallback to old session token
      yield call(
        sessionHelpers.setApplicationCookie,
        "authentication_token",
        currentUser.authentication_token
      )
    } else {
      yield call(sessionHelpers.setApplicationCookie, "authentication_token", "Bearer " + jwtToken)
    }

    yield call(sessionHelpers.setApplicationCookie, "remember_me_cookie", currentUser.email)

    yield call(sessionHelpers.setApplicationCookie, "current_user_id", currentUser.id)

    yield call(sessionHelpers.setApplicationCookie, "current_fleet_id", currentUser.fleet_id)

    yield call(
      sessionHelpers.setApplicationCookie,
      "current_user_name",
      `${currentUser.firstName} ${currentUser.lastName}`
    )

    yield call(
      sessionHelpers.setApplicationCookie,
      "current_user_email",
      action.payload.formData.email
    )
    yield call(
      sessionHelpers.setApplicationCookie,
      "current_user_password",
      action.payload.formData.password
    )

    const afterRequestData = currentUser

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SIGN_IN_FAILED" })

    const invalidAuth = ["Invalid email or password", "Email ou mot de passe invalide"]

    const alertMessage = invalidAuth.some((error) => errors.includes(error))
      ? "invalidAuthAlert"
      : apiErrorHelpers.toString(errors)

    const afterRequestData = {
      alertMessage: alertMessage,
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // signIn()
//Send OTP Start
export function* sendOTP(action) {
  try {
    yield put({ type: "SEND_OTP_REQUESTED" })

    const request = {
      url: "/sessions/send_otp",
      body: {
        email: action.payload.formData.email,
        password: action.payload.formData.password,
        query: "fleet_default",
        subdomain: action.payload.subdomain,
        is_resend_otp: action.payload.is_resend_otp,
        is_split_flag: action.payload.is_split_flag
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })
    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SEND_OTP_SUCCEEDED"
    })

    const afterRequestData = response

    if (action.callback) yield call(action.callback, "success")
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SEND_OTP_FAILED" })

    const invalidAuth = ["Invalid email or password", "Email ou mot de passe invalide"]

    const alertMessage = invalidAuth.some((error) => errors.includes(error))
      ? "invalidAuthAlert"
      : apiErrorHelpers.toString(errors)

    const afterRequestData = {
      alertMessage: alertMessage,
      alertType: "error"
    }

    if (action.callback) yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // send OTP end()

export function* signOut(action) {
  try {
    const { payload } = action
    let currentUser = payload.currentUser
    yield put({ type: "SIGN_OUT_REQUESTED" })
    let response
    let affiliation = yield select((state) => state.affiliations.affiliation) ||
      currentUser.affiliation ||
      {}

    if (isFleetMaintenanceHub()) {
      // Load the affiliation to distinguish Shell vs Alsco
      // TODO: this data loading should be done in a main data loader of the whole app
      if (affiliation.id !== currentUser.affiliation_id) {
        affiliation = yield call(loadAffiliation)
      }

      if (isShell(affiliation)) {
        api.setAuthApi({
          Authorization: sessionHelpers.getApplicationCookie("authentication_token")
        })

        response = yield call(api.utility.del, api.path("/shell_sessions"))
        apiErrorHelpers.catchErrors(response.body)
      }
    }

    const success = yield call(sessionHelpers.deleteApplicationCookies, [
      "authentication_token",
      "current_user_id",
      "current_user_name",
      "current_user_email"
    ])
    sessionStorage.removeItem("current_user")
    sessionStorage.removeItem("current_fleet")
    // eslint-disable-next-line
    if (success !== true) throw payload.t("somethingWentWrongelabel")

    yield put({ type: "SIGN_OUT_SUCCEEDED" })

    yield call(action.callback, affiliation)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SIGN_OUT_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
} // signOut()

export default function* sessionSaga() {
  yield takeEvery("CHANGE_PASSWORD_SAGA", changePassword)
  yield takeEvery("FORGOT_PASSWORD_SAGA", forgotPassword)
  yield takeEvery("RESET_PASSWORD_SAGA", resetPassword)
  yield takeEvery("SIGN_IN_SAGA", signIn)
  yield takeEvery("SIGN_OUT_SAGA", signOut)
  yield takeEvery("SIGN_UP_SAGA", signUp)
  yield takeEvery("SEND_OTP_SAGA", sendOTP)
}
